import { firebaseApp, db, auth } from "../firebase/FirebaseConfig";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { doc, getDoc, getDocs, where, query, collection, onSnapshot } from "firebase/firestore";
import UserContext from "./UserContext";
import { useState } from "react";
import md5 from "js-md5";
import { useCookies } from "react-cookie";

//Componente de alto orden que nos ayuda a manejar el contexto del
// sitio y alamcena los estados y variables con que puede interactuar
// un lider

export const UserState = (props) => {

    // coockie local del usuario
    const [cookies, setCookies, removeCookie] = useCookies(['user', 'admin', 'carrito'])
    //estados para manejar las cookies
    const [state, setState] = useState(cookies.user)
    //variables para controlar el login
    const [loginError, setLoginError] = useState(false);
    const [adminState, setAdminState] = useState(cookies.admin)
    //carrito de prductos del usuario
    const [carrito, setCarrito] = useState(cookies.carrito)
    //firebase login
    const Login = async (email, password) => {
        setLoginError(false)

        const docRef = collection(db, "users");

        const q = query(docRef, where("email", "==", email), where("password", "==", md5(password)));

        const querySnapshot = await getDocs(q);

        //console.log(querySnapshot)
        if (querySnapshot.size > 0) {
            querySnapshot.forEach((doc) => {
                setCookies("user", { id: doc.id, data: doc.data() }, { path: "/" });
                //console.log(cookies.user)
                setState({ id: doc.id, data: doc.data() })

                GetCarrito(doc.data().current_orden)
                setLoginError(false);
            });
        } else {

            removeCookie('user', { path: "/" });
            removeCookie('carrito', { path: "/" });
            setState(undefined)
            setCarrito(undefined)
            setLoginError(true);
        }

    }

    // Obtener el carrito del usuario
    const GetCarrito = async (id) => {
        const docRef = doc(db, "ordenes", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {

            GetCarritoProductos(docSnap)

        } else {
            // doc.data() will be undefined in this case
            alert("error al obtener el carrito, por favor cierre sesion e intentelo de nuevo")
            removeCookie('user', { path: "/" });
            removeCookie('carrito', { path: "/" });
            setState(undefined)
            setCarrito(undefined)
            setLoginError(true);
        }
    }

    const GetCarritoProductos = async (docSnap) => {
        const querySnapshot = await getDocs(collection(db, "ordenes/" + docSnap.id + "/productos"));
        let productosAux = []
        let montoTotalAux = 0;
        let costoPesoExtra = 0;
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            productosAux.push({ id: doc.id, data: doc.data() })
            montoTotalAux = (parseFloat(montoTotalAux) + parseFloat(doc.data().total)).toFixed(2)
            costoPesoExtra = (parseFloat(costoPesoExtra) + parseFloat(doc.data().costo_peso_extra)).toFixed(2)
            //console.log("encuentra producto");
        });
        //   console.log(montoTotalAux)
        //console.log("update carrito!!")
        //console.log(productosAux); 
        setCookies("carrito", {
            id: doc.id,
            data: docSnap.data(),
            productos: productosAux,
            total: montoTotalAux,
            costo_peso_extra: costoPesoExtra
        }, { path: "/" });
        setCarrito({
            id: docSnap.id,
            data: docSnap.data(),
            productos: productosAux,
            total: montoTotalAux,
            costo_peso_extra: costoPesoExtra
        });
        const unsub = onSnapshot(doc(db, "ordenes", docSnap.id), (doc) => {
            //console.log(cookies.carrito)
            //console.log(productosAux); 
            setCookies("carrito", {
                id: doc.id,
                data: doc.data(),
                productos: productosAux,
                total: montoTotalAux,
                costo_peso_extra: costoPesoExtra
            }, { path: "/" });
            setCarrito({
                id: docSnap.id,
                data: doc.data(),
                productos: productosAux,
                total: montoTotalAux,
                costo_peso_extra: costoPesoExtra
            });

        });
        //console.log(querySnapshot.docs)

    }
    const UpdateCarrito = async (id) => {
        const querySnapshot = await getDocs(collection(db, "ordenes/" + id + "/productos"));
        let productosAux = []
        let montoTotalAux = 0;
        let costoPesoExtra = 0;
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            productosAux.push({ id: doc.id, data: doc.data() })
            montoTotalAux = (parseFloat(montoTotalAux) + parseFloat(doc.data().total)).toFixed(2)
            costoPesoExtra = (parseFloat(costoPesoExtra) + parseFloat(doc.data().costo_peso_extra)).toFixed(2)
            //console.log(doc.data());
        });
        //console.log(productosAux)
        setCookies("carrito", {
            id: carrito.id,
            data: carrito.data,
            productos: productosAux,
            total: montoTotalAux,
            costo_peso_extra: costoPesoExtra,
        }, { path: "/" });

        //console.log(querySnapshot.docs)
        setCarrito({
            id: carrito.id,
            data: carrito.data,
            productos: productosAux,
            total: montoTotalAux,
            costo_peso_extra: costoPesoExtra,
        });
    }
    //cerrrar sesion
    const Logout = () => {
        //console.log(cookies.user)
        removeCookie('user', { path: "/" });
        removeCookie('carrito', { path: "/" });
        //removeCookie("user", { path: "/" })
        console.log("logout ");
        setState(undefined)
        setCarrito(undefined)
        //window.location.reload()
    }
    const LogoutAdmin = () => {

        signOut(auth).then(() => {
            //   cookie.remove('admin');
            removeCookie('admin', { path: "/" });
            console.log("admin logout ");
            setAdminState(undefined)

        }).catch((error) => {
            window.location.reload()

        });
    }

    //variable para manejar las pantallas y el es estatus de la compra
    // maneja las pantallas de /canasta tambien
    // Tipos dr estatus
    //En revisión
    //En espera de pago
    //En camino
    //

    //firebase Admin login
    const AdminLogin = (email, password) => {
        setLoginError(false)
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                //console.log(user);

                GetAdminExtraData(user)


            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode + " " + errorMessage);
                //cookie.remove('admin');
                setAdminState(undefined)
                setLoginError(true);

            });
    }

    // obtener datos de la db del usuario
    const GetAdminExtraData = async (user) => {
        const docRef = doc(db, "admins", user.uid);
        const docSnap = await getDoc(docRef);
        const extraData = docSnap.data();
        if (extraData !== undefined) {
            // cookie.set("admin", { user: user, extra_data: extraData }, { path: "/" });
            setCookies("admin", { user: user, extra_data: extraData }, { path: "/" })
            setAdminState({
                user: user,
                extra_data: extraData
            })
            setLoginError(false);
        }
        else {
            removeCookie('admin', { path: "/" });
            //cookie.remove('admin');
            console.log("logout ");
            setAdminState(undefined)
            setLoginError(true);
        }

    }
    const [statusCompra, setStatusCompra] = useState("")

    //variables para controlar los productos seleccionados 
    // por el usuario asi como las redes y subredes que ha escojido

    const [selectedRed, setSelectedRed] = useState("")
    const [selectedSubRed, setSelectedSubRed] = useState("")
    const [selectedProducto, setSelectedProducto] = useState("")
    return (
        <UserContext.Provider value={{
            user: state,
            admin: adminState,
            auth,
            Login,
            carrito, GetCarrito, UpdateCarrito, setCarrito,
            AdminLogin,
            loginError,
            Logout,
            LogoutAdmin,

            cookies,
            setCookies,

            statusCompra,
            setStatusCompra,

            selectedRed,
            setSelectedRed,

            selectedSubRed,
            setSelectedSubRed,

            selectedProducto,
            setSelectedProducto
        }}>
            {props.children}
        </UserContext.Provider>
    )

}