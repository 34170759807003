import { doc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import React, { useContext, useState } from 'react'
import { Alert, Container, Form, Spinner } from 'react-bootstrap'
import { db, storage } from '../../firebase/FirebaseConfig';
import { StatusCompra } from '../../user/StatusCompra';
import UserContext from '../../user/UserContext';

// tercera vista de la pantalla /canasta
// le muestra al lider la opcion para pagar sus productos
// es la tercera face de la compra

const CanastaView3 = () => {

    const { carrito } = useContext(UserContext);

    const [spinnerState, setSpinnerState] = useState(false)

    //variable para manejar la foto de perfil
    const [imagen, setImagen] = useState(undefined)
    const HandleImageUpload = (archivos) => {
        setImagen(archivos[0])
    }

    // guardar el link de la imagen en firestore
    const StatusChange2 = async (ref) => {
        await updateDoc(doc(db, "ordenes", carrito.id), {
            fotoPago: ref,
            status: StatusCompra.revisionDePago
        });
        setSpinnerState(false)
        alert("Tu comprobante se subió correctamente")
    }

    //guardar la imagen en storage y optener el link
    const StatusChange = () => {

        if (imagen !== undefined) {
            setSpinnerState(true)
            const storageRef = ref(storage, 'fotos_pagos/' + carrito.id);

            uploadBytes(storageRef, imagen).then((snapshot) => {
                getDownloadURL(storageRef).then(ref => {

                    StatusChange2(ref)
                }).catch((error) => {
                    const errorMessage = error.message;
                    alert("Error: " + errorMessage + "\ninténtalo de nuevo más tarde")
                    // ..
                });
            }).catch((error) => {
                const errorMessage = error.message;
                alert("Error: " + errorMessage + "\ninténtalo de nuevo más tarde")
                // ..
            });

        }else
        alert("Por favor agrega una imagen")


    }
    return (
        <>
            <Container className="text-center mt-5">
                <h2>En espera de pago</h2>
            </Container>

            <Container>
                <Container className="my-5 w-75 position-relative">
                    <div className="progress" style={{ height: "5px" }}>
                        <div className="progress-bar" role="progressbar" style={{ width: "66%" }} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <span style={{ left: "0%" }} className="position-absolute top-50 translate-middle btn-lg btn-primary rounded-circle">
                        1
                        <span className="visually-hidden">unread messages</span>
                    </span>

                    <span style={{ left: "33%" }} className="position-absolute top-50 translate-middle btn-lg btn-primary rounded-circle">
                        2
                        <span className="visually-hidden">unread messages</span>
                    </span>

                    <span style={{ left: "66%" }} className="position-absolute top-50 translate-middle btn-lg btn-primary rounded-circle">
                        3
                        <br />

                        <span className="visually-hidden">unread messages</span>
                    </span>

                    <span style={{ left: "100%" }} className="position-absolute top-50 translate-middle btn-lg btn-secondary rounded-circle">
                        4
                        <span className="visually-hidden">unread messages</span>
                    </span>

                </Container>
            </Container>
            <Container className="w-75 my-5 text-center">

                <Alert variant="info" className=" py-5">
                    <p>Tus productos están listos para ser enviados, por favor realiza el depósito con la cantidad de <strong>${parseFloat(carrito.data.monto)} </strong> a la cuenta:</p>
                    <br />
                    <h3 className="my-4">XXXX-XXXX-XXXX-XXXX-XXXX</h3>
                    <br />

                </Alert>
                <Alert variant="info" className=" py-5">
                    <p>Una vez realizado el depósito por favor sube una foto del váucher o ticket generado para su revisión.</p>
                    <>
                        {!spinnerState ?
                            <>
                                <div className="container w-50">
                                    <Form.Group controlId="file" className="py-4">
                                        <Form.Control type="file" onChange={(e) => HandleImageUpload(e.target.files)} />
                                    </Form.Group>
                                </div>
                                <input type="button" className="btn text-white bg-indigo mb-4" value="subir" onClick={() => StatusChange()} />
                            </>
                            :
                            <div className="text-center my-5 py-5">
                                <Spinner animation="border" variant="danger" />
                            </div>
                        }
                    </>

                    <p>una vez comprobado el pago tus productos se enviarán de manera inmediata.</p>
                </Alert>
            </Container>
        </>
    )
}

export default CanastaView3