import { collection, getDocs, query, where } from "@firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { db } from "../firebase/FirebaseConfig";
import UserContext from "../user/UserContext";
import ProductoCard from "./ProductoCard";
import '../../styles/ImpulsandoRedes.css'
// componenete que administra los productos 
// y sus categorias, despues de esto los muestra 
// en la pantalla "redDeXCategoria"

const ProductsComponent = (props) => {

    const { selectedRed, setSelectedSubRed } = useContext(UserContext);
    const [productosData, setProductosData] = useState(null)

    useEffect(() => {

        if (selectedRed !== "") {
            if (props.subRedes !== null) {
                var firstSubRed = ""

                props.subRedes.map((item, idx) => {
                    if (idx === 0)
                        firstSubRed = item
                    return true
                })

                // console.log(firstSubRed)
                ChangeSubRed(firstSubRed)
            }
        }

        // console.log("hola")

    }, [selectedRed, props.subRedes])

    const ChangeSubRed = async (item) => {

        setSelectedSubRed(item.id)

        const q = query(collection(db, "productos"), where("red", "==", selectedRed + "/" + item.id));
        // console.log(selectedRed + "/" + selectedSubRed)
        const querySnapshot = await getDocs(q);
        setProductosData(querySnapshot.docs)

    }
    return (
        <>
            <Row className="m-0">
                <Col sm={3} className="mt-3">
                    {props.subRedes !== null ?
                        <Container>
                            {props.subRedes.map((item, idx) => (
                                <button id={"subRedA" + idx} key={"subRedA" + idx} className="list-group-item list-group-item-action" onClick={() => ChangeSubRed(item)}>{item.id}</button>
                            ))}
                        </Container>
                        :
                        <div className="text-center my-5 py-5">
                            <Spinner animation="border" variant="danger" />
                        </div>
                    }

                </Col>
                <Col sm={9} className="mt-3">
                    <Container>


                        {productosData !== null 
                            ? <Row xs={1} md={4} className="">
                                {productosData.map((item, idx) => (
                                    <Col key={"prod" + idx}>
                                        <ProductoCard producto={item} />
                                    </Col>
                                ))}

                            </Row>
                            :
                            <div className="text-center my-5 py-5">
                                <Spinner animation="border" variant="danger" />
                            </div>}
                    </Container>
                </Col>
            </Row>

        </>
    )
}

export default ProductsComponent