import React, { useContext, useState } from "react";
import { Alert, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import UserContext from "../user/UserContext";
import logo from '../../assets/logo100.png'

// Componente que pide un usuario y una contraseña 
// hace una verificacion a la base de datos para ver si
// se ingresaron credenciales correctas
// se muestra en la pantalla "RedesDashboard"
const LoginComponent = () => {

    const [datosIngresados, setDatosIngresados] = useState({
        user: "",
        password: ""
    })

    const HandleChange = e => {
        const { name, value } = e.target
        setDatosIngresados((prevState) => ({
            ...prevState,
            [name]: value
        }))
        //console.log(itemSeleccionado)
    }
    // const auth = getAuth();

    const { Login, loginError } = useContext(UserContext)

    const ClickLogin = () => {

        Login(datosIngresados.user, datosIngresados.password)

    }

    return (
        <div className="redes-bg d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>

            <Card style={{ minWidth: "250px" }} className="w-25">
                <Card.Body>
                    <Card.Title className="text-center">
                    <img src = {logo} alt = "logo" className = "img-fluid"/>
                        <br />
                        <br />
                        Login</Card.Title>
                    {
                        loginError ?
                            <Alert variant="danger" className="my-2">
                                Usuario o contraseña incorrectos
                            </Alert>
                            :
                            <></>
                    }

                    <Form.Group className="mb-4" controlId="user">
                        <Form.Label>Usuario</Form.Label>
                        <Form.Control onChange={HandleChange} name="user" type="email" value={datosIngresados && datosIngresados.user} />
                    </Form.Group>

                    <Form.Group className="mb-4" controlId="password">
                        <Form.Label>Contraseña</Form.Label>
                        <Form.Control onChange={HandleChange} name="password" type="password" value={datosIngresados && datosIngresados.password} />
                    </Form.Group>
                    <input className="btn w-100 button-bg text-white mb-4" type="button" onClick={() => ClickLogin()} value="Ingresar"></input>
                    <Link to ="/admin" className="btn w-100 btn-link text-muted text-decoration-none" >Administrador</Link>

                </Card.Body>
            </Card>
        </div>
    )
}
export default LoginComponent