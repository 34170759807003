import { collection, deleteDoc, doc, getDocs, setDoc, updateDoc } from "firebase/firestore";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import React, { useState } from "react";
import { Form, Spinner, Table } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { db, storage } from "../../firebase/FirebaseConfig";
import { v4 as uuidv4 } from 'uuid';
import DropdownBeneficiosLogos from "./DropdownBeneficiosLogos";
import ShowLogosBeneficios from "./ShowLogosBeneficios";

const BeneficiosCRUD = () => {

    //datos recibidos de la base de datos 
    const [data, setData] = useState([])
    //manejadores para mostrar y ocultar los modales
    const [modalInsertar, setModalInsertar] = useState(false)
    const [modalEditar, setModalEditar] = useState(false)
    const [modalEliminar, setModalEliminar] = useState(false);

    //se activan en el on click de los botones
    //insertar, eliminar, editar correspondientemente
    const OpenCLoseModalInsertar = () => {
        setModalInsertar(!modalInsertar)
    }
    const OpenCLoseModalEliminar = () => {
        setModalEliminar(!modalEliminar)
    }
    const OpenCLoseModalEditar = () => {
        setModalEditar(!modalEditar)
    }

    //Metodo get para obtener los items de la DB
    const GetItems = async () => {
        const querySnapshot = await getDocs(collection(db, "beneficios"));
        setData(querySnapshot.docs)

    }

    const [itemSeleccionado, setitemSeleccionado] = useState({
        logo: "",
        title: "",
        beneficio: "",
        direccion: "",
        map: "",
        especialidad: ""

    })
    const [editItemSeleccionado, setEditItemSeleccionado] = useState(undefined)
    const SeleccionarUsuario = (item, caso) => {
        setitemSeleccionado(item.data());
        setEditItemSeleccionado(item);
        (caso === "editar") ?
            OpenCLoseModalEditar()
            :
            OpenCLoseModalEliminar()
    }
    const HandleChange = e => {
        const { name, value } = e.target
        setitemSeleccionado((prevState) => ({
            ...prevState,
            [name]: value
        }))
        //console.log(itemSeleccionado)
    }
    const [imagen, setImagen] = useState(undefined)
    const HandleLogoChange = (e) => {
        console.log(e);
        setitemSeleccionado((prevState) => ({
            ...prevState,
            logo: e
        }))
        document.getElementById("LogoBeneficiosArray").innerHTML = "Logo " + e
    }

    const AddBeneficio = async () => {
        if (itemSeleccionado.logo !== "" && itemSeleccionado.title !== ""
            && itemSeleccionado.beneficio !== "" && itemSeleccionado.direccion !== ""
            && itemSeleccionado.map !== "") {
            const temID = uuidv4();
            await setDoc(doc(db, "beneficios", temID), itemSeleccionado);
            GetItems()
            OpenCLoseModalInsertar()
            alert("¡Entrada creada correctamente!");


        } else {
            alert("Por favor rellena todos los campos")
        }
    }
    const DeleteBeneficio = async () => {
        await deleteDoc(doc(db, "beneficios", editItemSeleccionado.id));
        GetItems()
        OpenCLoseModalEliminar()
        alert("¡Producto eliminado correctamente!")

    }

    const UpdateBeneficio = async () => {
        if (itemSeleccionado.logo !== "" && itemSeleccionado.title !== ""
            && itemSeleccionado.beneficio !== "" && itemSeleccionado.direccion !== ""
            && itemSeleccionado.map !== "") {
            await updateDoc(doc(db, "beneficios", editItemSeleccionado.id), itemSeleccionado);
            GetItems()
            OpenCLoseModalEditar()
            alert("¡Beneficio editado correctamente!");
        }
    }
    return (
        <>
            <div className="container text-center my-5 border border-info border-2">
                <h1>Beneficios<button className="btn text-white bg-indigo m-5" onClick={() => OpenCLoseModalInsertar()}>Nuevo beneficio</button></h1>
                <input type="button" className="btn btn-primary mb-5" value="Ver beneficio" onClick={() => GetItems()} />
                {data !== [] ?
                    <>
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Logo</th>
                                    <th>Título</th>
                                    <th>Especialidad</th>
                                    <th>Beneficio</th>
                                    <th>Dirección</th>
                                    <th>Link mapa</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody className="align-middle">
                                {data.map((item, idx) => (
                                    <tr key={"beneficio" + idx}>
                                        <td> <ShowLogosBeneficios logoNumber={item.data().logo} /></td>
                                        <td>{item.data().title}</td>
                                        <td>{item.data().especialidad}</td>
                                        <td>{item.data().beneficio}</td>
                                        <td>{item.data().direccion}</td>
                                        <td>{item.data().map}</td>
                                        <td>
                                            <button className="btn btn-primary m-1" onClick={() => SeleccionarUsuario(item, "editar")}>Editar</button>
                                            <button className="btn btn-danger" onClick={() => SeleccionarUsuario(item, "eliminar")}>Eliminar</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <Modal
                            show={modalInsertar}
                            onHide={() => OpenCLoseModalInsertar()}>
                            <Modal.Header closeButton>Nuevo beneficio</Modal.Header>
                            <Modal.Body>
                                <div className="form-group">
                                    <label className="form-label">Logo</label>
                                    <br />
                                    <DropdownBeneficiosLogos HandleLogoChange={HandleLogoChange} />
                                    <br />
                                    <label className="form-label">Título</label>
                                    <br />
                                    <input className="form-control mb-3" type="text" name="title" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Especialidad (opcional)</label>
                                    <br />
                                    <input className="form-control mb-3" type="text" name="especialidad" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Beneficio</label>
                                    <br />
                                    <input className="form-control mb-3" type="text" name="beneficio" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Dirección</label>
                                    <br />
                                    <input className="form-control mb-3" type="text" name="direccion" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Link maps</label>
                                    <br />
                                    <input className="form-control mb-3" type="text" name="map" onChange={HandleChange}></input>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button onClick={() => AddBeneficio()} className="btn btn-primary">Aceptar</button>
                                <button onClick={() => OpenCLoseModalInsertar()} className="btn btn-danger">Cancelar</button>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            show={modalEditar}
                            onHide={() => OpenCLoseModalEditar()}>
                            <Modal.Header closeButton>Editar beneficio</Modal.Header>
                            <Modal.Body>
                                <div className="form-group">
                                    <label className="form-label">Logo</label>
                                    <br />
                                    <DropdownBeneficiosLogos HandleLogoChange={HandleLogoChange} />
                                    <br />
                                    <label className="form-label">Título</label>
                                    <br />
                                    <input className="form-control mb-3" value={itemSeleccionado && itemSeleccionado.title} type="text" name="title" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Especialidad (opcional)</label>
                                    <br />
                                    <input className="form-control mb-3" value={itemSeleccionado && itemSeleccionado.especialidad} type="text" name="especialidad" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Beneficio</label>
                                    <br />
                                    <input className="form-control mb-3" value={itemSeleccionado && itemSeleccionado.beneficio} type="text" name="beneficio" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Dirección</label>
                                    <br />
                                    <input className="form-control mb-3" value={itemSeleccionado && itemSeleccionado.direccion} type="text" name="direccion" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Link maps</label>
                                    <br />
                                    <input className="form-control mb-3" value={itemSeleccionado && itemSeleccionado.map} type="text" name="map" onChange={HandleChange}></input>

                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button type="submit" onClick={() => UpdateBeneficio()} className="btn btn-primary">Editar</button>
                                <button onClick={() => OpenCLoseModalEditar()} className="btn btn-danger">Cancelar</button>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={modalEliminar}
                            onHide={() => OpenCLoseModalEliminar()}>
                            <Modal.Header closeButton>Eliminar beneficio</Modal.Header>
                            <Modal.Body>
                                <div className="text-center">
                                    <p>¿Estás seguro que deseas eliminar la entrada {itemSeleccionado && itemSeleccionado.title}?</p>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button onClick={() => DeleteBeneficio()} className="btn btn-danger">Sí</button>
                                <button onClick={() => OpenCLoseModalEliminar()} className="btn btn-primary">No</button>
                            </Modal.Footer>
                        </Modal>
                    </>
                    :
                    <div className="text-center mt-4">
                        <Spinner animation="grow" variant="danger" />
                    </div>
                }
            </div>
        </>
    )

}

export default BeneficiosCRUD