import { getAuth } from '@firebase/auth';
import { getFirestore } from '@firebase/firestore';
import { getStorage } from '@firebase/storage';
import { initializeApp } from 'firebase/app';

// ayudante para manejar la base de datos de la aplicación
// llaves y configuración de FB
const  firebaseConfig = {
        
    apiKey: "AIzaSyArEW9lLXRXSevsWRvt45pKJR_tXKg5DiU",
    authDomain: "impulsando-redes.firebaseapp.com",
    databaseURL: "https://impulsando-redes-default-rtdb.firebaseio.com",
    projectId: "impulsando-redes",
    storageBucket: "impulsando-redes.appspot.com",
    messagingSenderId: "1085875938340",
    appId: "1:1085875938340:web:ad705117869e898f23e4b1",
    measurementId: "G-5EHGDQN37T"  
  
};
const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore()
const storage = getStorage(firebaseApp)
const auth = getAuth();
export {firebaseApp , db, storage, auth}