import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../components/general/footer";
import NavBar from '../components/general/NavBar';
import '../styles/About.css'
import imgAbout from '../assets/about5.2.jpg'
import {AnimateComponent} from '../components/utilities/AnimateComponent'
// pagina que muestra la asociación, sus valores, mision, descripción, etc...
// un "Quienes somos"
// se accede como /quienes-somos

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        AnimateComponent("animation1", 'erase-animation-transition', 'erase-animation-wrapper1')
        AnimateComponent("animation2", 'erase-animation-transition', 'erase-animation-wrapper2')
        AnimateComponent("animation3", 'erase-animation-transition', 'erase-animation-wrapper4')
        AnimateComponent("animation4", 'erase-animation-transition', 'erase-animation-wrapper4')

        //icon animations
        AnimateComponent("animation5", 'erase-animation-transition', 'erase-animation-wrapper5')
        AnimateComponent("animation-icon-1", 'erase-animation-transition', 'erase-animation-wrapper-icon-1')
        AnimateComponent("animation-icon-2", 'erase-animation-transition', 'erase-animation-wrapper-icon-2')
        AnimateComponent("animation-icon-3", 'erase-animation-transition', 'erase-animation-wrapper-icon-3')
        AnimateComponent("animation-icon-4", 'erase-animation-transition', 'erase-animation-wrapper-icon-4')
        AnimateComponent("animation-icon-5", 'erase-animation-transition', 'erase-animation-wrapper-icon-5')
        AnimateComponent("animation-icon-6", 'erase-animation-transition', 'erase-animation-wrapper-icon-6')
        AnimateComponent("animation-icon-7", 'erase-animation-transition', 'erase-animation-wrapper-icon-7')
        AnimateComponent("animation-icon-8", 'erase-animation-transition', 'erase-animation-wrapper-icon-8')
    }, [])

    return (
        <>
            <NavBar />
            <div className="about-banner-image w-100  d-flex justify-content-center align-items-center">
                <div className="content text-center">

                    <h1 className="border border-white p-3 text-white text-bold">¿Quiénes somos?</h1>

                </div>
            </div>

            <Row xs={1} md={2} className="g-5 p-0 m-0">
                <Col id="erase-animation-wrapper1" className="d-flex justify-content-center align-items-center  erase-animation-wrapper">
                    <Container id="animation1" className="d-flex justify-content-center align-items-center erase erase-animation">
                        <p className="">
                            Impulsando sueños AC, fundada por el Ing. Luis Herrera Altamirano, surge en 2014
                            como un proyecto de Voluntarios y se constituye como Asociación Civil legal y fiscal en
                            2020. Nace con el objetivo de contribuir al bienestar social integral, para mejorar la
                            calidad de vida de los más desfavorecidos, considerando la salud como el pilar
                            fundamental sobre el que se apoya el desarrollo social.
                            <br/>
                            Para llevar a cabo nuestra labor nos hemos fijado un propósito y unos valores.
                        </p>

                    </Container>
                </Col>
                <Col id="erase-animation-wrapper2" className="erase-animation-wrapper p-0 m-0">
                    <div id="animation2" className="bg-about erase erase-animation">
                        <img alt="..." src={imgAbout} className="img-fluid" />
                    </div>

                </Col>
            </Row>

            <Row xs={1} md={2} className="g-5 px-0 m-0 py-5 bg-proposito-1">
                <Col lg={4} id="erase-animation-wrapper3" className="erase-animation-wrapper py-5 m-0 ">
                    <div id="animation3" className="erase erase-animation w-100 h-100 d-flex justify-content-center align-items-center">
                        <img alt="..." className="p-0 m-0" src="https://img.icons8.com/cotton/100/000000/hand-holding-heart--v3.png" />
                    </div>

                </Col>
                <Col lg={8} id="erase-animation-wrapper4" className="erase-animation-wrapper py-5">
                    <Container id="animation4" className="text-white erase erase-animation">
                        <h3>Propósito</h3>
                        <p>
                            Impulsar el bienestar social implementando proyectos y programas dirigidos a jóvenes
                            y grupos comunitarios organizados, favoreciendo la cohesión social.
                        </p>
                    </Container>
                </Col>

            </Row>
            <div className="bg-valores py-5 text-center text-white">
                <div id="erase-animation-wrapper5">
                    <h3 id="animation5" className="erase erase-animation">Nuestros valores</h3>
                </div>

                <Row xs={2} md={4} className="g-5 px-0 m-0 py-5">
                    <div id="erase-animation-wrapper-icon-1">
                        <h4 id="animation-icon-1" className="erase erase-animation">Solidaridad
                            <br />
                            <img alt="..." src="https://img.icons8.com/bubbles/100/000000/friends--v4.png" />
                        </h4>
                    </div>
                    <div id="erase-animation-wrapper-icon-2">
                        <h4 id="animation-icon-2" className="erase erase-animation">Respeto
                            <br />
                            <img alt="..." src="https://img.icons8.com/bubbles/100/000000/trust.png" />
                        </h4>
                    </div>

                    <div id="erase-animation-wrapper-icon-3">
                        <h4 id="animation-icon-3" className="erase erase-animation">
                            Justicia social
                            <br />
                            <img alt="..." src="https://img.icons8.com/bubbles/100/000000/scales.png" />
                        </h4>
                    </div>

                    <div id="erase-animation-wrapper-icon-4">
                        <h4 id="animation-icon-4" className="erase erase-animation">
                            Dignidad humana
                            <br />
                            <img alt="..." src="https://img.icons8.com/bubbles/100/000000/hearts.png" />
                        </h4>
                    </div>

                    <div id="erase-animation-wrapper-icon-5">
                        <h4 id="animation-icon-5" className="erase erase-animation">
                            Igualdad
                            <br />
                            <img alt="..." src="https://img.icons8.com/bubbles/100/000000/seed-of-life.png" />
                        </h4>
                    </div>

                    <div id="erase-animation-wrapper-icon-6">
                        <h4 id="animation-icon-6" className="erase erase-animation">
                            Participación
                            <br />
                            <img alt="..." src="https://img.icons8.com/bubbles/100/000000/hand-cursor.png" />
                        </h4>
                    </div>

                    <div id="erase-animation-wrapper-icon-7">
                        <h4 id="animation-icon-7" className="erase erase-animation">
                            Colaboración
                            <br />
                            <img alt="..." src="https://img.icons8.com/bubbles/100/000000/handshake.png" />
                        </h4>
                    </div>

                    <div id="erase-animation-wrapper-icon-8">
                        <h4 id="animation-icon-8" className="erase erase-animation">
                            Sostenibilidad
                            <br />
                            <img alt="..." src="https://img.icons8.com/bubbles/100/000000/geography.png" />
                        </h4>
                    </div>

                </Row>
            </div>
            <Footer />
        </>
    )
}
export default About