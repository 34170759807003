import { deleteDoc, doc } from "@firebase/firestore";
import React, { useContext, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { db } from "../firebase/FirebaseConfig";
import UserContext from "../user/UserContext";

// Tarjeta que se muestra en CanastaView1, muestra los detalles 
// de un producto que el lider almacenó en su canasta

const CanastaDetallesCard = (props) => {
    const [spinerHandler, setSpinerHandler] = useState(false)
    const { UpdateCarrito, user } = useContext(UserContext)
    const DeleteProducto = async () => {
        setSpinerHandler(true)
        await deleteDoc(doc(db, "ordenes/" + user.data.current_orden + "/productos", props.producto.id));
        UpdateCarrito(user.data.current_orden)
        setSpinerHandler(false)
    }
    return (
        <>
            {!spinerHandler
                ?
                <div className="shadow rounded mb-4" >
                    <div className="text-center bg-aqua text-white rounded-top" >
                        <p className="p-2">{props.producto.data.name}</p>
                    </div>
                    <Row className="p-2 d-flex justify-content-center align-items-center">
                        <Col>
                            <img style={{ height: "80px" }} className="img-fluid rounded-circle" src={props.producto.data.foto} alt="..." />
                        </Col>

                        <Col className="">
                            {props.producto.data.cantidad} unidades
                        </Col>

                        <Col>
                            ${props.producto.data.total}
                        </Col>

                        <Col>
                            <button className="border-0 bg-white" onClick={() => DeleteProducto()}>
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                    width="30" height="30"
                                    viewBox="0 0 172 172"
                                    style={{ fill: "#000000" }}><g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixBlendMode: "normal" }}><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#e74c3c"><path d="M71.66667,14.33333l-7.16667,7.16667h-35.83333v14.33333h7.16667v107.5c0,3.74259 1.37119,7.55804 4.07324,10.26009c2.70205,2.70205 6.5175,4.07324 10.26009,4.07324h71.66667c3.74259,0 7.55804,-1.37119 10.26009,-4.07324c2.70205,-2.70206 4.07324,-6.5175 4.07324,-10.26009v-107.5h7.16667v-14.33333h-35.83333l-7.16667,-7.16667zM50.16667,35.83333h71.66667v107.5h-71.66667zM64.5,50.16667v78.83333h14.33333v-78.83333zM93.16667,50.16667v78.83333h14.33333v-78.83333z"></path></g></g></svg>
                            </button>
                        </Col>
                    </Row>
                </div>
                :
                <div className="text-center my-5 py-5">
                    <Spinner animation="border" variant="danger" />
                </div>
            }

        </>
    )
}

export default CanastaDetallesCard