import React from "react";
import { Alert, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

// Segunda vista de la pantalla /canasta
// le muestra al lider que su productos fueron pedidos
// es la segunda face de la compra

const CanastaView2 = () => {

    return (
        <>
            <Container className="text-center mt-5">
                <h2>Confirmación de productos</h2>
            </Container>

            <Container>
                <Container className="my-5 w-75 position-relative">
                    <div className="progress" style={{ height: "5px" }}>
                        <div className="progress-bar" role="progressbar" style={{ width: "33%" }} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <span style={{ left: "0%" }} className="position-absolute top-50 translate-middle btn-lg btn-primary rounded-circle">
                        1
                        <span className="visually-hidden">unread messages</span>
                    </span>

                    <span style={{ left: "33%" }} className="position-absolute top-50 translate-middle btn-lg btn-primary rounded-circle">
                        2
                        <span className="visually-hidden">unread messages</span>
                    </span>

                    <span style={{ left: "66%" }} className="position-absolute top-50 translate-middle btn-lg btn-secondary rounded-circle">
                        3
                        <br />

                        <span className="visually-hidden">unread messages</span>
                    </span>

                    <span style={{ left: "100%" }} className="position-absolute top-50 translate-middle btn-lg btn-secondary rounded-circle">
                        4
                        <span className="visually-hidden">unread messages</span>
                    </span>

                </Container>
            </Container>
            <Container className="w-75 my-5 text-center">

                <Alert variant = "info" className = " py-5">Tus productos fueron ordenados al administrador, están siendo revisados en nuestro almacén, una vez confirmado la existencia de los productos y la dirección de entrega se habilitará un token para que realices tu pago, trabajamos de la manera más rápida posible en este proceso, agradecemos por su paciencia</Alert>
                
                <Alert variant = "info" className = " py-5">Puedes monitorear el estado de tu entrega en los <Link to = "/perfil"> movimientos de tu perfil</Link></Alert>

            </Container>

        </>
    )
}

export default CanastaView2