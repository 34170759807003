import React from "react";
import '../../styles/ImpulsandoRedes.css'
// Tarjeta usada en la ventana "impulsando redes" la 
//cual se usa para explicar que funcion tiene
// cada una de las redes de ayuda
const CustomCard = (props) =>{

    return(
        <>
        <div className = {"card border-0 shadow text-white text-center"} style={{maxWidth: "25rem"}}>
            <div className = "inner">
                <img src={props.img} className="card-img-top" alt="..."/>
            </div>
            
            <div className="card-body py-4">
                <div className={props.iconImg + " shadow position-absolute start-50 translate-middle p-5 rounded-circle"}>
                    <span className="visually-hidden">New alerts</span>
                </div>
                <h5 className="card-title mt-5 text-dark mb-0">{props.title}</h5>                            
            </div>
        </div>
        </>
    )

}

export default CustomCard