import React, { useContext } from "react";
import { Container, Nav, Navbar, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import UserContext from "../../user/UserContext";
import NavLogo from '../../../assets/logoSM.png'

// Barra de navegacion que aparece en las pantallas del administrador
// implementa los atajos necesarios para un administrador 

const NavBarAdmin = () => {
    const { LogoutAdmin } = useContext(UserContext)

    return (
        <>
            <Navbar className=" py-3 navbar-dark bg-purple" expand="md">
                <Container className="container-fluid">
                    <Link to="/admin" className="navbar-brand">
                        <img src={NavLogo} alt="logo" className="img-fluid" />
                    </Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="" />
                    <Navbar.Collapse id="basic-navbar-nav" className="">
                        <Nav className="ms-auto">
                            <Button variant="link" className="nav-link" onClick={() => LogoutAdmin()}>Cerrar sesión</Button>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>)
}

export default NavBarAdmin

    // < Link to = "/admin" className = "text-center nav-link" > Inicio</Link >
    //     <Link to="/perfil" className="text-center nav-link">Perfil</Link>