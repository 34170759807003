import { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import ShowLogosBeneficios from '../Redes/Admin/ShowLogosBeneficios';

const BeneficiosCard = ({ logo, title, especialidad, txt,direccion, map }) => {

    const [modal, setModal] = useState(false)

    const OpenCLoseModal = () => {
        setModal(!modal)
    }

    return (
        <div>
            <div onClick={() => OpenCLoseModal()} className=" rounded-pill card shadow mb-3">
                <Row xs={2} md={2} className="g-0">
                    <Col xs={4} md={4} className="d-flex justify-content-start align-items-center">
                        <ShowLogosBeneficios logoNumber = {logo}/>
                    </Col>
                    <Col xs={8} md={8} className="">
                        <div className="card-body">
                            <h5 className="card-title">{title}</h5>
                            <p className="card-text"><small className="text-muted">{txt}</small></p>
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal
                show={modal}
                onHide={() => OpenCLoseModal()}>
                <Modal.Header closeButton>Descripción</Modal.Header>
                <Modal.Body>
                    <h4>{title}</h4>
                    {especialidad && <p>{especialidad}</p>} 
                    <hr/>
                    <h4>Beneficio:</h4>
                    <p>{txt}</p>
                    <hr/>
                    <h4>Dirección:</h4>
                    <p>{direccion}</p>
                    <a href={map} target="_blank" rel="noopener noreferrer">Ver ubicación</a>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => OpenCLoseModal()} className="btn bg-indigo text-white">Cerrar</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default BeneficiosCard