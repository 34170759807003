import React from 'react'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import '../../styles/BlogCard.css'
//tarjeta que muestra las entradas del blog del sitio
// se muestra en "/" la pantalla de inicio

const BlogCard = (props) => {

    const [show, setShow] = useState(false)
    const HandleModal = () => {
        setShow(!show)
    }
    return (
        <>
            <div className="col">
                <Link to="#" onClick={() => { HandleModal() }} className="card border-0 bg-dark text-white">
                    <div className="inner">
                        <img src={props.item.data().foto} className="ImageCardBg card-img" alt="..." />
                    </div>

                    <div className="card-img-overlay">
                        <h5 className="card-title">{props.item.data().title}</h5>
                        <p className="badge bg-indigo">
                            <span className="position-absolute top-0 start-50 translate-middle p-2 bg-danger border border-light rounded-circle">
                            </span>
                            {props.item.data().date}
                        </p>
                    </div>
                </Link>
            </div>
            <Modal
                size="xl"
                show={show}
                onHide={() => HandleModal()}>
                <Modal.Header className='bg-purple text-white' closeButton>
                    <Modal.Title>{props.item.data().title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container text-center">
                        <br /><br />
                        <div className=' text-start '>
                            <h6 className="bg-secondary p-2 text-white rounded d-inline mr-5">{props.item.data().date} </h6>
                        </div>
                        <br />
                        <hr class="divider"/>
                        <h5 className='text-start'>{props.item.data().content.split("<br/>").map((element,idx) => (
                            <p key ={"p" + idx}>{element}</p>
                        ))}</h5>
                        <br />
                        <br />
                        <hr class="divider"/>
                        <img src={props.item.data().foto} className="w-50 text-center card-img" alt="..." />
                        <br />
                        <br />
                    </div>
                    

                </Modal.Body>
                <Modal.Footer >

                    <button onClick={() => HandleModal()} className="me-5 btn text-white bg-indigo btn" to="/comunicados"> Cerrar </button>
                    
                </Modal.Footer>
                
            </Modal>
        </>
    )

}

export default BlogCard