import { Col, Container, Row, Spinner } from "react-bootstrap"
import BeneficiosCard from "../components/general/BeneficiosCard"
import Footer from "../components/general/footer"
import NavBar from "../components/general/NavBar"
import logo1 from '../assets/icon-health-1.1.png'
import logo2 from '../assets/icon-home-1.png'
import logo3 from '../assets/icon-energy-1.png'
import logo4 from '../assets/icon-inclusion-1.png'
import { useEffect, useState } from "react"
import { collection, getDocs } from "firebase/firestore"
import { db } from "../components/firebase/FirebaseConfig"
const Beneficios = () => {

    const [data, setData] = useState(undefined)
    const GetBeneficios = async () => {
        const querySnapshot = await getDocs(collection(db, "beneficios"));
        setData(querySnapshot.docs)
    }
    useEffect(() => {
        GetBeneficios()        
    },[])
    return (
        <>
            <NavBar />
            <div className="about-banner-image w-100  d-flex justify-content-center align-items-center">
                <div className="content text-center">

                    <h1 className="border border-white p-3 text-white text-bold">Beneficios</h1>

                </div>
            </div>
            <Container className="py-5">
                {data !== undefined ?
                    <Row xs={1} md={2} className="g-3">
                        {data.map((item, idx) => (
                            <Col key={"beneficio" + idx}>
                                <BeneficiosCard logo={item.data().logo} title={item.data().title} direccion={item.data().direccion} map={item.data().map} especialidad={item.data().especialidad} txt={item.data().beneficio} />
                            </Col>
                        ))}
                    </Row>
                    :
                    <div className="text-center mt-4">
                        <Spinner animation="border" variant="danger" />
                    </div>
                }


            </Container>
            <Footer />
        </>
    )
}

export default Beneficios

