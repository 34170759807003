import React, { useEffect } from "react";
import { Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import Footer from "../components/general/footer";
import NavBar from "../components/general/NavBar";

// pagina que busca enviar un correo a el administrador
// con el objetivo de tener un contacto directo con los usuarios y lideres
// se accede como /contactanos

const Contactanos = () =>{
    useEffect(()=>{
        window.scrollTo(0,0);
      },[])
    
      return(
        <>
            <NavBar/>
            <div className = "contactanos-banner-image w-100">
                <div style = {{paddingTop: "30vh"}} className = "d-flex justify-content-center align-items-center">
                    <div className="content text-center text-white ">
                        <h1 className="border border-white p-3 text-bold">Contactanos</h1>
                        
                        
                    </div>
                </div>
                <Container className = "text-center">
                    <p className = " py-5 text-white text-center">Para cualquier duda, sugerencia o algún tipo de información, estamos a tus órdenes</p>

                    <FloatingLabel controlId="nombre" label="Nombre" className="pb-5">
                        <Form.Control type="text" placeholder="Nombre Apellidos" />
                    </FloatingLabel>

                    <Row className="g-2">
                        <Col md>
                            <FloatingLabel controlId="telefono" label="Telefono" className="pb-5">
                                <Form.Control type="number" placeholder="42531594561" />
                            </FloatingLabel>
                        </Col>
                        <Col md>
                            <FloatingLabel controlId="email" label="Correo electrónico" className="pb-5">
                                <Form.Control type="email" placeholder="something@example.com" />
                            </FloatingLabel>        
                        </Col>
                    </Row>
                    <FloatingLabel controlId="asunto" label="Asunto" className="pb-5">
                        <Form.Control type="text" placeholder="asunto" />
                    </FloatingLabel>

                    <FloatingLabel controlId="mensaje" label="Mensaje" className="pb-5">
                        <Form.Control type="text" placeholder="Mensaje"  as="textarea" style={{ height: '150px' }}/>
                    </FloatingLabel>

                    <input className = "btn btn-lg button-bg text-white mb-5" type = "button" value = "Enviar" ></input>

                </Container>
            </div>
            
            <Footer/>
        </>
      )
        
    
}
export default Contactanos