import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Main from './Main';
import '../styles/general.css'
import About from './About';
import ImpulsandoRedes from './ImpulsandoRedes';
import Contactanos from './Contactanos';
import Redes from './Redes/Redes';
import RedesDashboard from './Redes/RedesDashboard';
import { UserState } from '../components/user/UserState';
import Producto from './Redes/Producto';
import Canasta from './Redes/Canasta';
import AdminDashBoard from './Redes/AdminDashBoard';
import ListaDeProductos from './Redes/ListaDeProductos';
import Beneficios from './Beneficios';
// manejador del browserRouter
// aqui se ponen todas las rutas que va a tener
// el sitio web

function App() {

  return (
    <BrowserRouter>
      <Switch>
        <UserState>
          <Route exact path="/" component={Main} />
          <Route exact path="/quienes-somos" component={About} />
          <Route exact path="/areas-de-actuacion" component={ImpulsandoRedes} />
          <Route exact path="/contactanos" component={Contactanos} />


          <Route exact path="/redes" component={Redes} />
          <Route exact path="/perfil" component={RedesDashboard} />

          <Route exact path="/lista-de-productos" component={ListaDeProductos} />
          <Route exact path="/producto" component={Producto} />
          <Route exact path="/canasta" component={Canasta} />
          <Route exact path="/admin" component={AdminDashBoard} />
          <Route exact path="/beneficios" component={Beneficios} />

        </UserState>
      </Switch>
    </BrowserRouter>
  );
}

export default App;