import React from "react";
import { Navbar } from "react-bootstrap";

// pie de pagina que se muestra en todas las ventanas en las que un usuario
// se ha logueado, es decir la parte de "tienda"
const FooterRedes = () => {
    return (
        <>
            <Navbar className=" py-3 navbar-dark  bg-purple" expand="md">
                <div className="text-white text-center w-100">
                IMPULSANDO SUEÑOS ES UNA ASOCIACIÓN CIVIL SIN FINES DE LUCRO, NI RELIGIOSOS <br />
                                Politica de privacidad | Aviso legal | &#169; 2021 Impulsando Sueños Ac. Todos los derechos reservados
                </div>

            </Navbar>
        </>
    )
}

export default FooterRedes