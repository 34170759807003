import React, { useContext, useEffect } from "react";
import CanastaView1 from "../../components/Redes/canasta/CanastaView1";
import CanastaView2 from "../../components/Redes/canasta/CanastaView2";
import CanastaView3 from "../../components/Redes/canasta/CanastaView3";
import CanastaView4 from "../../components/Redes/canasta/CanastaView4";
import CanastaView5 from "../../components/Redes/canasta/CanastaView5";
import FooterRedes from "../../components/Redes/FooterRedes";
import NavBarRedes from "../../components/Redes/NavBarRedes";
import { StatusCompra } from "../../components/user/StatusCompra";
import UserContext from "../../components/user/UserContext";

// Pantalla que muestra el carrito/canasta del lider logueado
// Sirve para hacer crud a la canasta y proceder al pago
// si asi se desea
// se accede como /canasta

const Canasta = () => {
    const {carrito, user } = useContext(UserContext)
    useEffect(()=>{
        window.scrollTo(0,0);
      },[])
    return (
        <>
            {user === undefined ?
                <>
                    {window.location.href = "/redes"}
                </>
                :
                <>
                    <NavBarRedes />
                    {carrito.data.status === StatusCompra.comprando && <CanastaView1/> }
                    {carrito.data.status === StatusCompra.enRevision && <CanastaView2/> }
                    {carrito.data.status === StatusCompra.enEsperaDePago && <CanastaView3/> }
                    {carrito.data.status === StatusCompra.revisionDePago && <CanastaView4/> }
                    {(carrito.data.status === StatusCompra.enCamino || carrito.data.status === StatusCompra.finalizado) && <CanastaView5/> }
                    
                    <FooterRedes />
                </>
            }
        </>
    )
}

export default Canasta