import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Spinner, Table, Dropdown, Button, DropdownButton } from 'react-bootstrap';
import { db } from "../../firebase/FirebaseConfig";
import { collection, deleteDoc, setDoc,  doc, getDocs, updateDoc } from '@firebase/firestore';
import { StatusCompra } from '../../user/StatusCompra';
import ProductoCard from './ProductoCard';
import { v4 as uuidv4 } from 'uuid';

// UI del crud para el administrador que controla las ordenes
// o pedidos hechos por los lideres
// se muestra en /admin

const AllMovimientos = () => {
    // estados 
    //datos recibidos de la base de datos 
    const [data, setData] = useState(undefined)
    //manejadores para mostrar y ocultar los modales
    const [modalEliminar, setModalEliminar] = useState(false);

    const [modalVerProductos, setModalVerProductos] = useState(false);

    //objeto para identificar con cual item de la lista estamos
    //trabajando de manera especifica
    const [itemSeleccionado, setitemSeleccionado] = useState({})

    //se activan en el on click de los botones
    const [finalizarOrden, setFinalizarOrden] = useState(false);
    const OpenCLoseModalFinalizarOrden = (doc) => {
        setitemSeleccionado(doc)
        setFinalizarOrden(!finalizarOrden)
    }
    const FinalizarOrden = async () => {
        const ref = doc(db, "ordenes", itemSeleccionado.id);

        await updateDoc(ref, {
            status: StatusCompra.finalizado
        });
        CreateNewOrder()
        OpenCLoseModalFinalizarOrden()
    }
    //Crea una nueva orden cuando una se marca como "finalizado",
    //esto para que es lider pueda seguir comprando con una orden nueva
    const CreateNewOrder = async () => {
        const temID = uuidv4();
        await setDoc(doc(db, "ordenes", temID),
            {
                comment: "",
                costo_envio: 0,
                date: "",
                fotoPago: "",
                costo_peso_extra: 0,
                monto: 0,
                status: StatusCompra.comprando,
                user: itemSeleccionado.data().user
            });
            ChangeCurrentOrderUser(temID);

    }
    //se activa en cadena cuando el status de una orden se marca como "finalizado"
    //canbia en la tabla users la orden actual del usuario que tenia la orden
    //que ya finalizó
    const ChangeCurrentOrderUser = async (orderID) => {
        const ref = doc(db, "users", itemSeleccionado.data().user);

        // Set the "capital" field of the city 'DC'
        await updateDoc(ref, {
            current_orden: orderID
        });
        GetItems()
    }
    const OpenCLoseModalEliminar = () => {
        setModalEliminar(!modalEliminar)
    }
    const OpenCLoseModalVerProducto = () => {
        setModalVerProductos(!modalVerProductos)
    }

    //Metodo get para obtener los items de la DB
    const GetItems = async () => {
        const querySnapshot = await getDocs(collection(db, "ordenes"));

        setData(querySnapshot.docs)
    }
    const [productosData, setProductosData] = useState(undefined)
    const GetProductos = async (id) => {
        const querySnapshot = await getDocs(collection(db, "ordenes/" + id + "/productos"));
        //console.log("ordenes/" + data.id + "/productos")
        setProductosData(querySnapshot.docs)
    }

    //Metodo POST para enviar un nuevo item a la DB
    const ChangeStatus = async (event, orden) => {
        const ref = doc(db, "ordenes", orden.id);

        await updateDoc(ref, {
            status: event
        });
        GetItems()
    }

    //borrar una fila de la db
    const DeleteOrden = async () => {

        await deleteDoc(doc(db, "ordenes", itemSeleccionado.id));
        GetItems()
        OpenCLoseModalEliminar()
    }
    // se activa en el onclick de los botones eliminar y editar abriendo el modal
    //correspondiente
    const SeleccionarUsuario = (item, opcion) => {
        setitemSeleccionado(item)
        if (opcion === "ver") {
            GetProductos(item.id)
            OpenCLoseModalVerProducto()
        } else {
            OpenCLoseModalEliminar()
        }

    }

    //get en cuanto inicia la pagian
    useEffect(() => {
        GetItems()
        // console.log(data)
    }, [])

    //render el cual retorna la lista de la db
    //botones de editar y eliminar y modal de agregar una nuevo item del carrusel
    //NOTA: una cuadro de cada carrusel tiene 3 items
    //El modal para editar
    //el modal para eliminar
    //es decir un crud basico
    return (
        <>
            <div className="container text-center">
                <h1>Ordenes</h1>
                {data !== undefined ?
                    <>
                        <div className="table-responsive" style={{ paddingBottom: "250px", marginBottom: "" }}>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Lider</th>
                                        <th>Status</th>
                                        <th>Comentarios</th>
                                        <th>Monto</th>
                                        <th>Productos</th>
                                        <th>Foto Pago</th>
                                        <th>Opciones</th>
                                    </tr>
                                </thead>
                                <tbody className="align-middle">
                                    {data.map(doc => (
                                        <tr key={"orden" + doc.id}>
                                            <td>{new Date(doc.data().date.seconds * 1000).toLocaleDateString("es-MX")}</td>
                                            <td>{doc.data().user}</td>
                                            <td>
                                                <DropdownButton variant="primary" id={"dDStatus" + doc.id} title={doc.data().status} onSelect={(e) => ChangeStatus(e, doc)}>
                                                    <Dropdown.Item eventKey={StatusCompra.comprando} >{StatusCompra.comprando}</Dropdown.Item>
                                                    <Dropdown.Item eventKey={StatusCompra.enRevision} >{StatusCompra.enRevision}</Dropdown.Item>
                                                    <Dropdown.Item eventKey={StatusCompra.enEsperaDePago}>{StatusCompra.enEsperaDePago}</Dropdown.Item>
                                                    <Dropdown.Item eventKey={StatusCompra.revisionDePago} >{StatusCompra.revisionDePago}</Dropdown.Item>
                                                    <Dropdown.Item eventKey={StatusCompra.enCamino}>{StatusCompra.enCamino}</Dropdown.Item>
                                                </DropdownButton>

                                                {doc.data().status !== StatusCompra.finalizado && <Button variant='danger' onClick={() => OpenCLoseModalFinalizarOrden(doc)} className='mt-1'>{StatusCompra.finalizado}</Button>}
                                            </td>
                                            <td>{doc.data().comment}</td>
                                            <td>${doc.data().monto}</td>
                                            <td><button className="btn btn-link" onClick={() => SeleccionarUsuario(doc, "ver")}>Ver</button></td>
                                            <td><a target="_blank" rel="noopener noreferrer" href={doc.data().fotoPago}><img width="100" src={doc.data().fotoPago} alt="" className="image-fluid" /></a></td>
                                            <td>

                                                <button className="btn btn-danger" onClick={() => SeleccionarUsuario(doc, "eliminar")}>Eliminar</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                        <Modal
                            show={modalEliminar}
                            onHide={() => OpenCLoseModalEliminar()}>
                            <Modal.Header closeButton>Eliminar orden</Modal.Header>
                            <Modal.Body>
                                <div className="text-center">
                                    <p>¿Estás seguro que deseas eliminar esta orden?</p>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button onClick={() => DeleteOrden()} className="btn btn-danger">Sí</button>
                                <button onClick={() => OpenCLoseModalEliminar()} className="btn btn-primary">No</button>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            show={modalVerProductos}
                            onHide={() => OpenCLoseModalVerProducto()}>
                            <Modal.Header closeButton>Productos ordenados</Modal.Header>
                            {productosData !== undefined ?
                                <Modal.Body>
                                    {productosData.map((doc, idx) => (
                                        <ProductoCard key={"prod" + idx} item={doc} />
                                    ))}
                                </Modal.Body>
                                :
                                <div className="text-center mt-4">
                                    <Spinner animation="grow" variant="danger" />
                                </div>
                            }
                            <Modal.Footer>
                                <button onClick={() => OpenCLoseModalVerProducto()} className="btn btn-danger">Cerrar</button>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            show={finalizarOrden}
                            onHide={() => OpenCLoseModalFinalizarOrden()}>
                            <Modal.Header closeButton>Finalizar Orden</Modal.Header>

                            <Modal.Body>
                                ¿Marcar como finalizada la orden?, esto se le notificará al líder que ordenó los productos
                            </Modal.Body>

                            <Modal.Footer>
                                <button onClick={() => FinalizarOrden()} className="btn btn-primary">Marcar como finalizado</button>
                                <button onClick={() => OpenCLoseModalFinalizarOrden()} className="btn btn-danger">Cancelar</button>
                            </Modal.Footer>
                        </Modal>
                    </>
                    :
                    <div className="text-center mt-4">
                        <Spinner animation="grow" variant="danger" />
                    </div>
                }
            </div>

        </>
    )
}

export default AllMovimientos