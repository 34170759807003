// normalizacion de los nombres de los nombres de las redes
export const NombresRedes = {
    vivienda : "Red de vivienda",
    salud : "Red de salud",
    alimentacion: "Red de alimentación",
    iluminacion : "Red de iluminación / energía",
    arte : "Red de arte / cultura",
    deporte : "Red de Deporte / Juventud",
    medioAmbiente : "Red de medio ambiente",
    educacion : "Red de educación",
    innovacion : "Red de innovación y gestión del conocimiento",
    seguridad : "Red de seguridad humana",
    inclusion : "Red de inclusión productiva y financiera"
}