import React from "react";
import { Alert, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

// cuarta vista de la pantalla /canasta
// le muestra al lider que su pago está en revision
// es la cuarta face de la compra

const CanastaView4 = () => {

    return (
        <>
            <Container className="text-center mt-5">
                <h2>En revisión de pago</h2>
            </Container>

            <Container>
                <Container className="my-5 w-75 position-relative">
                    <div className="progress" style={{ height: "5px" }}>
                        <div className="progress-bar" role="progressbar" style={{ width: "100%" }} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <span style={{ left: "0%" }} className="position-absolute top-50 translate-middle btn-lg btn-primary rounded-circle">
                        1
                        <span className="visually-hidden">unread messages</span>
                    </span>

                    <span style={{ left: "33%" }} className="position-absolute top-50 translate-middle btn-lg btn-primary rounded-circle">
                        2
                        <span className="visually-hidden">unread messages</span>
                    </span>

                    <span style={{ left: "66%" }} className="position-absolute top-50 translate-middle btn-lg btn-primary rounded-circle">
                        3
                        <br />

                        <span className="visually-hidden">unread messages</span>
                    </span>

                    <span style={{ left: "100%" }} className="position-absolute top-50 translate-middle btn-lg btn-primary rounded-circle">
                        4
                        <span className="visually-hidden">unread messages</span>
                    </span>

                </Container>
            </Container>
            <Container className="w-75 my-5 text-center">

                <Alert variant="info" className=" py-5">
                    <h4>¡Gracias por tu confianza!
                    </h4>
                    <br />
                    <p> Nuestro administrador está revisando el comprobante de tu pago, una vez verificado enviaremos tus productos lo más pronto posible
                    </p>
                
                </Alert>

                <Alert variant = "info" className = " py-5">Puedes monitorear el estado de tu entrega en los <Link to = "/perfil"> movimientos de tu perfil</Link></Alert>
            </Container>
        </>
    )
}

export default CanastaView4

