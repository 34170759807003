import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Container, Nav, Navbar, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import '../../styles/NavBar.css'
import UserContext from "../user/UserContext";
import CarritoOffCanvas from "./CarritoOffCanvas";
import NavLogo from '../../assets/logoSM.png'
// barra de navegacion que muestra accesos rapidos a todas las funcionalidades
// que tiene un lider que se ha logueado

//import { Logout } from "../firebase/FBControler";
// import { getAuth, signOut } from "firebase/auth";
const NavBarRedes = () => {

    const { Logout,carrito, GetCarrito } = useContext(UserContext)
    //manejadore para visualizar el carrito de compras 
    const [show, setShow] = useState(false);
    const handleOffCanvas = () => setShow(!show);

    useEffect(() =>{
        if(carrito !== undefined)
            GetCarrito(carrito.id)
    },[])
    return (
        <>
            <Navbar className=" py-3 navbar-dark bg-purple" expand="md">
                <Container className="container-fluid">
                    <Link to="/redes" className="navbar-brand">
                        <img src = {NavLogo} alt = "logo" className = "img-fluid"/>
                    </Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="" />
                    <Navbar.Collapse id="basic-navbar-nav" className="">
                        <Nav className="ms-auto">

                            <Link to="/redes" className="text-center nav-link">Inicio</Link>
                            <Link to="/perfil" className="text-center nav-link">Perfil</Link>
                            <Button variant="link" className="nav-link position-relative" onClick={() => handleOffCanvas()} >
                                Mi canasta
                                <span className="position-absolute top-0 start-75 translate-middle badge rounded-pill bg-primary">
                                    {carrito !== undefined && carrito.productos.length}
                                    <span className="visually-hidden">unread messages</span>
                                </span>
                            </Button>
                            <Button variant="link" className="nav-link" onClick={() => Logout()}>Cerrar sesión</Button>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <CarritoOffCanvas carrito = {carrito} show = {show} handleOffCanvas = {handleOffCanvas}/>

        </>
    )
}
export default NavBarRedes