import React from 'react'
import { Col, Row } from 'react-bootstrap'
import ImgRedAlimentacion from '../../assets/impulsando-redes-alimentacion.jpg'
import ImgRedSalud from '../../assets/impulsando-redes-salud.jpg'
import ImgRedVivienda from '../../assets/impulsando-redes-vivienda.jpg'
import ImgRedAmbiente from '../../assets/impulsando-redes-ambiente.jpg' 
import ImgRedIluminacion from '../../assets/impulsando-redes-iluminacion.jpg'
import ImgRedArte from '../../assets/impulsando-redes-arte.jpg'
import ImgRedEducacion from '../../assets/impulsando-redes-educacion.jpg'
import ImgRedInnovacion from '../../assets/impulsando-redes-innovacion.jpg'
import ImgRedSeguridad from '../../assets/impulsando-redes-seguridad.jpg'
import ImgRedSport from '../../assets/impulsando-redes-sport.jpg'
import ImgRedInclusion from '../../assets/impulsando-redes-inclusion.jpg'
import RedesCard from './RedesCard'
import { NombresRedes } from '../utilities/NombresRedes'

// alamacena y administra las "RedesCard"
// se implementa en la pantalla "Redes"

const SeleccionarRed = () => {

    return (
        <>
            <Row xs={1} md={3} className="py-5 m-0 g-5">
                <Col>
                    <RedesCard route={NombresRedes.vivienda} bgVariant="bg-pink" img={ImgRedVivienda} iconImg="bg-red-vivienda" title="Red de vivienda" />
                </Col>
                <Col >
                    <RedesCard route={NombresRedes.salud} bgVariant="bg-primary" img={ImgRedSalud} iconImg="bg-red-salud" title="Red de salud" />
                </Col>
                <Col >
                    <RedesCard route={NombresRedes.alimentacion} bgVariant="bg-indigo" img={ImgRedAlimentacion} iconImg="bg-red-alimentacion" title="Red de alimentación" />
                </Col>
                <Col>
                    <RedesCard route={NombresRedes.iluminacion} bgVariant="bg-info" img={ImgRedIluminacion} iconImg="bg-red-energia" title="Red de iluminación / energía" />
                </Col>
                <Col>
                    <RedesCard route={NombresRedes.arte} bgVariant="bg-warning" img={ImgRedArte} iconImg="bg-red-arte" title="Red de arte / cultura" />
                </Col>
                <Col>
                    <RedesCard route={NombresRedes.deporte} bgVariant="bg-pink" img={ImgRedSport} iconImg="bg-red-sport" title="Red de Deporte / Juventud" />
                </Col>
                <Col>
                    <RedesCard route={NombresRedes.medioAmbiente} bgVariant="bg-primary" img={ImgRedAmbiente} iconImg="bg-red-ambiente" title="Red de medio ambiente" />
                </Col>
                <Col>
                    <RedesCard route={NombresRedes.educacion} bgVariant="bg-indigo" img={ImgRedEducacion} iconImg="bg-red-educacion" title="Red de educación" />
                </Col>
                <Col>
                    <RedesCard route={NombresRedes.innovacion} bgVariant="bg-info" img={ImgRedInnovacion} iconImg="bg-red-innovacion" title="Red de innovación y gestión del conocimiento" />
                </Col>
                <Col>
                    <RedesCard route={NombresRedes.seguridad} bgVariant="bg-warning" img={ImgRedSeguridad} iconImg="bg-red-seguridad" title="Red de seguridad Humana" />
                </Col>
                <Col>
                    <RedesCard route={NombresRedes.inclusion} bgVariant="bg-pink" img={ImgRedInclusion} iconImg="bg-red-inclusion" title=" Inclusión productiva y financiera" />
                </Col>
            </Row>
        </>
    )
}

export default SeleccionarRed