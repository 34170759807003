import React, { useEffect } from 'react'
import NavBar from '../components/general/NavBar'
import Footer from '../components/general/footer'

import ImgRedAlimentacion from '../assets/impulsando-redes-alimentacion.jpg'
import ImgRedSalud from '../assets/impulsando-redes-salud.jpg'
import ImgRedAmbiente from '../assets/impulsando-redes-ambiente.jpg' 
import ImgRedIluminacion from '../assets/impulsando-redes-iluminacion.jpg'
import ImgRedArte from '../assets/impulsando-redes-arte.jpg'
import ImgRedEducacion from '../assets/impulsando-redes-educacion.jpg'
import ImgRedInnovacion from '../assets/impulsando-redes-innovacion.jpg'
import ImgRedSeguridad from '../assets/impulsando-redes-seguridad.jpg'
import ImgRedSport from '../assets/impulsando-redes-sport.jpg'
import ImgRedInclusion from '../assets/impulsando-redes-inclusion.jpg'
import '../styles/ImpulsandoRedes.css'
import { Col, Container, Row } from 'react-bootstrap'
import CustomCard from '../components/ImpulsandoRedes/CustomCard'

// Muetra tarjetas con informacion de las diferentes 
// redes en la que actua el sitio
// se accede como /areas-de-actuacion

const ImpulsandoRedes = () =>{
    useEffect(()=>{
        window.scrollTo(0,0);
      },[])
    return (
        
        <>
            <NavBar/>
            <div className = "about-banner-image w-100  d-flex justify-content-center align-items-center">
                <div className="content text-center">
                
                    <h1 className="border border-white p-3 text-white text-bold">Redes del bienestar</h1>
                    
                </div>
            </div>
            <Container className = "py-5">
                <Row xs={1} md={3} className="p-0 m-0 g-5">
                    <Col>
                        <CustomCard bgVariant = "bg-gray" img = {ImgRedAlimentacion} iconImg = "bg-red-vivienda" title = "Red de vivienda"/>
                    </Col>
                    <Col>
                        <CustomCard bgVariant="bg-gray" img={ImgRedSalud} iconImg="bg-red-salud" title="Red de salud" />
                    </Col>
                    <Col >
                    <CustomCard route="/red-de-vivienda" img={ImgRedAlimentacion} iconImg="bg-red-alimentacion" title="Red de alimentación" />
                </Col>
                <Col>
                    <CustomCard route="/red-de-vivienda" img={ImgRedIluminacion} iconImg="bg-red-energia" title="Red de iluminación / energía" />
                </Col>
                <Col>
                    <CustomCard route="/red-de-vivienda" img={ImgRedArte} iconImg="bg-red-arte" title="Red de arte / cultura" />
                </Col>
                <Col>
                    <CustomCard route="/red-de-vivienda" img={ImgRedSport} iconImg="bg-red-sport" title="Red de Deporte / Juventud" />
                </Col>
                <Col>
                    <CustomCard route="/red-de-vivienda" img={ImgRedAmbiente} iconImg="bg-red-ambiente" title="Red de medio ambiente" />
                </Col>
                <Col>
                    <CustomCard route="/red-de-vivienda" img={ImgRedEducacion} iconImg="bg-red-educacion" title="Red de educación" />
                </Col>
                <Col>
                    <CustomCard route="/red-de-vivienda" img={ImgRedInnovacion} iconImg="bg-red-innovacion" title="Red de innovación y gestión del conocimiento" />
                </Col>
                <Col>
                    <CustomCard route="/red-de-vivienda" img={ImgRedSeguridad} iconImg="bg-red-seguridad" title="Red de seguridad Humana" />
                </Col>
                <Col>
                    <CustomCard route="/red-de-vivienda" img={ImgRedInclusion} iconImg="bg-red-inclusion" title=" Inclusión productiva y financiera" />
                </Col>
                    
                    
                </Row>
                
            </Container>
                
            <Footer/>
        </>)
}

export default ImpulsandoRedes
