import { collection, getDocs } from "@firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { db } from "../../components/firebase/FirebaseConfig";
import FooterRedes from "../../components/Redes/FooterRedes";
import NavBarRedes from "../../components/Redes/NavBarRedes";
import ProductsComponent from "../../components/Redes/ProductsComponent";
import UserContext from "../../components/user/UserContext";
import { UnFormatData } from "../../components/utilities/FormatData";

// Muestra las subcategorias y los productos disponibles 
// para la red de vivienda
// se accede como /lista-de-productos

const ListaDeProductos = () => {

    const { user, selectedRed } = useContext(UserContext);
    const [subRedesData, setSubRedesData] = useState(null)
    useEffect( () => {
        
        window.scrollTo(0, 0);

        async function GetSubRedes(){
            const querySnapshot = await getDocs(collection(db, selectedRed));
            setSubRedesData(querySnapshot.docs)    
        } 
        if (selectedRed !== "") {
            GetSubRedes()
        }
    }, [selectedRed])
    return (
        <>
            {user === undefined || selectedRed === "" ?
                <>
                    {window.location.href = "/redes"}
                </>
                :
                <>
                    <NavBarRedes />
                    <div className={"mt-3 height-a text-white d-flex justify-content-center align-items-center" }>
                        <h1 style = {{border: "outset 1px #e71f64"}} className = "shadow rounded-pill text-dark p-3 " >{UnFormatData(selectedRed.toString()) }</h1>
                    </div >
                    <Container className="my-5">
                        <div className="text-center">
                            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aut reiciendis doloribus consequuntur cumque quos inventore atque, quis expedita fugit ab sequi commodi possimus, voluptatibus quam ipsum repudiandae, nihil rem voluptate.</p>
                        </div>


                    </Container>
                    <ProductsComponent subRedes = {subRedesData} />
                    <FooterRedes />
                </>
            }

        </>)

}
export default ListaDeProductos